import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'

export const getAnswerVariant = (answer) => {
    if (answer?.loading) {
        return { variant: 'secondary', icon: faSpinner }
    }
    if (answer?.error) {
        return { variant: 'danger', icon: faCircleExclamation }
    }
    if (answer?.from_earlier_survey) {
        return { variant: 'warning', icon: faCircleQuestion }
    }
    return { variant: 'success', icon: faCircleCheck }
}
