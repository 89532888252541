import React from 'react'
import { Button } from 'react-bootstrap'
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons/faCircleQuestion'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './Legend.module.scss'

const Legend = () => {
    return (
        <div className="mt-5">
            <h2>Legende</h2>
            <div className={styles.group}>
                <div className={styles.item}>
                    <Button variant="success">
                        <FontAwesomeIcon icon={faCircleCheck} />
                    </Button>
                    Antwort wurde gespeichert.
                </div>
                <div className={styles.item}>
                    <Button variant="secondary">
                        <FontAwesomeIcon icon={faSpinner} />
                    </Button>
                    Speichern läuft.
                </div>
                <div className={styles.item}>
                    <Button variant="warning">
                        <FontAwesomeIcon icon={faCircleQuestion} />
                    </Button>
                    Antwort wurde aus vorheriger Abfrage übernommen.
                </div>
                <div className={styles.item}>
                    <Button variant="danger">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                    </Button>
                    Antwort konnte nicht gespeichert werden. Für Details bitte anklicken.
                </div>
            </div>
        </div>
    )
}

export default Legend
