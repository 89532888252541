import { useContext } from 'react'
import { Context } from '../store'
import { getAnswerKey } from '../utils/getAnswerKey'
import { DEFAULT_ANSWER } from '../config/constants'

export const useAnswers = () => {
    const [state] = useContext(Context)
    const getAnswer = (surveyId, productId, questionId) => {
        const answer = state.answers[getAnswerKey({
            surveyId, productId, questionId
        })]

        return answer || { ...DEFAULT_ANSWER, surveyId, productId, questionId }
    }

    return { getAnswer }
}
