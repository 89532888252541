import React from 'react'
import PropTypes from 'prop-types'

const Field = ({ answer }) => {
    if (typeof answer === 'undefined' || answer?.value === '') {
        return ''
    }

    const isMultiselect = answer.question_type === 'multiselect'

    if (!isMultiselect) return answer.value

    return (
        <ul>
            {answer.value.map((v, i) => (
                <li key={i}>{v}</li>
            ))}
        </ul>
    )
}

Field.propTypes = {
    answer: PropTypes.any
}

export default Field
