import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styles from './Field.module.scss'
import { Button, Form } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import InputGroup from 'react-bootstrap/InputGroup'
import Multiselect from 'multiselect-react-dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'underscore'
import { useAnswers } from '../hooks/useAnswers'
import { Popover } from './Popover'
import { getAnswerVariant } from '../utils/getAnswerVariant'
import { useFieldCallbacks } from '../hooks/useFieldCallbacks'

const Field = ({ product, question, surveyId }) => {
    const isMultiselect = question?.type === 'multiselect'
    const { getAnswer } = useAnswers()
    const answer = getAnswer(surveyId, product.id, question.id)
    const { variant, icon } = getAnswerVariant(answer)
    const [inputId] = useState(_.uniqueId('input_'))
    const fieldRef = useRef(null)
    const {
        onMultiselectChange,
        onValueChange,
        resubmit,
        onBlur
    } = useFieldCallbacks({
        isMultiselect,
        answer,
        questionType: question.type,
        fieldRef
    })

    return (
        <Form.Group>
            <InputGroup className={styles['input-group']}>
                {answer.answered && (
                    <OverlayTrigger trigger="click" rootClose placement="top" overlay={
                        <Popover surveyId={surveyId} product={product} question={question} resubmit={resubmit}/>
                    }>
                        <Button variant={variant}>
                            <FontAwesomeIcon icon={icon} />
                        </Button>
                    </OverlayTrigger>
                )}
                {['text', 'number'].includes(question.type) && (
                    <Form.Control
                        id={inputId}
                        type="text"
                        value={answer?.value}
                        onChange={onValueChange}
                        disabled={answer?.disabled}
                        onBlur={onBlur}
                        ref={fieldRef}
                    />
                )}
                {question.type === 'select' && (
                    <Form.Select
                        id={inputId}
                        value={answer?.value}
                        disabled={answer?.disabled}
                        onChange={onValueChange}
                        onBlur={onBlur}
                        ref={fieldRef}
                    >
                        <option value="">Select</option>
                        {question.options?.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Form.Select>
                )}
                {isMultiselect && (
                    <Multiselect
                        id={inputId}
                        options={question.options}
                        selectedValues={answer?.value}
                        onSelect={onMultiselectChange}
                        onRemove={onMultiselectChange}
                        displayValue="label"
                        className={styles.multiselect}
                        disable={answer?.disabled}
                        ref={fieldRef}
                        style={{
                            multiselectContainer: {
                                width: '300px'
                            },
                            searchBox: {
                                whiteSpace: 'nowrap'
                            },
                            chips: {
                                background: '#909090'
                            }
                        }}
                    />
                )}
            </InputGroup>
        </Form.Group>
    )
}

Field.propTypes = {
    product: PropTypes.any,
    question: PropTypes.any,
    surveyId: PropTypes.number
}

export default Field
