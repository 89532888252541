export const API_ENDPOINT = '/api/fe'

// Delay before submitting input values
export const INPUT_DELAY = 1000

export const DEFAULT_ANSWER = {
    value: '',
    loading: false,
    disabled: false,
    answered: false
}
