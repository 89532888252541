import { getAnswerKey } from '../utils/getAnswerKey'

const Reducer = (state, action) => {
    switch (action.type) {
    case 'SET_ANSWER':
        return {
            ...state,
            answers: {
                ...state?.answers,
                [getAnswerKey(action.payload)]: action.payload
            }
        }
    case 'SET_ANSWERS':
        return {
            ...state,
            answers: action.payload.reduce((answers, answer) => {
                answers[getAnswerKey(answer)] = {
                    ...answer
                }
                return answers
            }, {})
        }
    default:
        return state
    }
}

export default Reducer
