import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Table from '../components/Table'
import api from '../services/api'
import { Context } from '../store'
import Legend from '../components/Legend'
import { DEFAULT_ANSWER } from '../config/constants'

const Surveys = () => {
    const { id } = useParams()
    const [survey, setSurvey] = useState({})
    const [, dispatch] = useContext(Context)

    useEffect(() => {
        api.getSurveys(id).then(survey => {
            setSurvey(survey)

            const payload = survey?.answers?.map(({
                product_id: productId,
                question_id: questionId,
                value,
                question_type: questionType,
                from_earlier_survey: fromEarlierSurvey,
                ...props
            }) => {
                const answer = {
                    ...DEFAULT_ANSWER,
                    ...props,
                    surveyId: survey.id,
                    productId,
                    questionId,
                    questionType,
                    fromEarlierSurvey,
                    answered: true
                }

                if (questionType !== 'multiselect') {
                    return { ...answer, value }
                }

                // Multiselect needs values in form {label, value}
                const question = survey.questions.find(question => question.id === questionId)
                const multiselectValue = question.options.filter(option => value.includes(option.value))

                return { ...answer, value: multiselectValue }
            })

            payload?.length > 0 && dispatch({ type: 'SET_ANSWERS', payload })
        })
    }, [id, dispatch])

    return (
        <>
            <Table survey={survey}/>
            {survey.id && <Legend />}
        </>
    )
}

export default Surveys
