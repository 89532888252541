import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import styles from './Hierarchy.module.scss'

const Hierarchy = ({ parents }) => {
    if (parents.length === 0) {
        return null
    }
    return (
        <div>
            {parents.map((parent, index) => {
                const style = {
                    fontSize: 14,
                    marginLeft: 10 * index,
                    marginBottom: 5
                }
                const Icon = index > 0 ? <FontAwesomeIcon icon={faCaretRight} className={styles.icon}/> : null
                return (
                    <p key={index} style={style}>
                        {Icon}
                        {parent}
                    </p>
                )
            })}
        </div>
    )
}

Hierarchy.propTypes = {
    parents: PropTypes.arrayOf(PropTypes.string)
}

export default Hierarchy
