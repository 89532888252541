import { useCallback, useContext, useEffect, useState } from 'react'
import _ from 'underscore'
import api from '../services/api'
import { INPUT_DELAY } from '../config/constants'
import { Context } from '../store'

export const useFieldCallbacks = ({ answer, isMultiselect, questionType, fieldRef }) => {
    const [, dispatch] = useContext(Context)
    const [focus, setFocus] = useState(false)

    const setAnswer = useCallback(async (answer) => {
        let value = answer.value
        if (isMultiselect && value) {
            value = answer.value.map(option => option.value.toString())
        }

        const requestBody = {
            ...answer,
            value
        }
        const response = await api.setAnswer(requestBody)

        const payload = {
            ...answer,
            loading: false,
            errors: response?.errors,
            error: response?.error,
            ...response?.body
        }

        dispatch({ type: 'SET_ANSWER', payload })
    }, [dispatch, isMultiselect])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setAnswerDebounced = useCallback(_.debounce(setAnswer, INPUT_DELAY), [])

    const onChange = useCallback(({ value, disabled = false }) => {
        const newAnswer = {
            ...answer,
            value,
            disabled,
            loading: true,
            answered: true
        }
        dispatch({ type: 'SET_ANSWER', payload: newAnswer })
        setAnswerDebounced(newAnswer)
    }, [answer, dispatch, setAnswerDebounced])

    const resubmit = useCallback(() => {
        onChange(answer)
    }, [answer, onChange])

    const onValueChange = useCallback((event) => {
        onChange({ value: event.target.value })
    }, [onChange])

    const onMultiselectChange = useCallback((selectedList) => {
        onChange({ value: selectedList })
    }, [onChange])

    useEffect(() => {
        if (focus && typeof fieldRef?.current?.focus === 'function') {
            fieldRef?.current?.focus()
            setFocus(false)
        }
    }, [focus, fieldRef])

    const onBlur = () => {
        if (answer.loading || !answer.answered) {
            setAnswerDebounced.cancel()
            setAnswer({
                ...answer,
                answered: true,
                loading: true
            })
        }
    }

    return {
        onMultiselectChange,
        onValueChange,
        resubmit,
        onBlur
    }
}
