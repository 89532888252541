import React, { useEffect, useState } from 'react'
import Table from '../components/Table'
import api from '../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons/faCloudArrowDown'
import { Button } from 'react-bootstrap'

const Report = () => {
    const [survey, setSurvey] = useState({})

    useEffect(() => {
        api.getReport().then(survey => {
            setSurvey(survey)
        })
    }, [])

    return (
        <>
            {survey?.error
                ? (
                    <div>
                        <h1>Preview Error</h1>
                        <p>{survey.error}</p>
                    </div>
                )
                : (
                    <Table survey={survey} isReport/>
                )}
            <Button href="/report/download" target="_blank">
                Herunterladen <FontAwesomeIcon icon={faCloudArrowDown} />
            </Button>
        </>
    )
}

export default Report
